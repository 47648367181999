import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "windDirectionName",
})
export class WindDirectionNamePipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  public transform(value: number): string {
    if (value === undefined || value === null) return "";
    const direction: number = Math.round((value % 360) / 45) * 45;
    let cardinalDirection: string = "";
    switch (direction) {
      case 0:
      case 360:
        cardinalDirection = this.translate.instant("STATION_PAGE.N");
        break;
      case 45:
        cardinalDirection = this.translate.instant("STATION_PAGE.NE");
        break;
      case 90:
        cardinalDirection = this.translate.instant("STATION_PAGE.E");
        break;
      case 135:
        cardinalDirection = this.translate.instant("STATION_PAGE.SE");
        break;
      case 180:
        cardinalDirection = this.translate.instant("STATION_PAGE.S");
        break;
      case 225:
        cardinalDirection = this.translate.instant("STATION_PAGE.SW");
        break;
      case 270:
        cardinalDirection = this.translate.instant("STATION_PAGE.W");
        break;
      case 315:
        cardinalDirection = this.translate.instant("STATION_PAGE.NW");
        break;
    }
    return cardinalDirection;
  }
}
