import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WindDirectionNamePipe } from "./wind-direction-name.pipe";
import { TranslatePipe } from "@ngx-translate/core";

@NgModule({
  declarations: [WindDirectionNamePipe],
  imports: [CommonModule],
  exports: [WindDirectionNamePipe],
})
export class WindDirectionNamePipeModule {}
