import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { StationComponent } from "./station.component";
import { TranslateModule } from "@ngx-translate/core";
import { SuppressScrollEventModule } from "src/app/directives/suppress-scroll-event/suppress-scroll-event.module";
import { EmptyDataModule } from "src/app/components/empty-data/empty-data.module";
import { ShareUrlModule } from "src/app/directives/share-url/share-url.module";
import { ChartModule } from "src/app/components/chart/chart.module";
import { WindDirectionNamePipeModule } from "../../pipes/wind-direction-name/wind-direction-name.module";

@NgModule({
  declarations: [StationComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SuppressScrollEventModule,
    EmptyDataModule,
    ShareUrlModule,
    ChartModule,
    WindDirectionNamePipeModule,
  ],
  providers: [DatePipe],
})
export class StationModule {}
