/**
 * RiscBal API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AlertDto } from '../model/models';
import { AllDataDto } from '../model/models';
import { BlackSpotDataDto } from '../model/models';
import { BlackSpotDto } from '../model/models';
import { CatchmentDto } from '../model/models';
import { ErrorResponseDto } from '../model/models';
import { HydroDataDto } from '../model/models';
import { IslandDto } from '../model/models';
import { ManyPolygonDto } from '../model/models';
import { MapPointDto } from '../model/models';
import { PolygonDto } from '../model/models';
import { StationDataDto } from '../model/models';
import { StationDto } from '../model/models';
import { VariableDataDto } from '../model/models';
import { WatershedDto } from '../model/models';
import { ZoneDataDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    LegacyServiceInterface,
    LegacyControllerGetAllCatchmentsRequestParams,
    LegacyControllerGetAnyCatchmentBlackSpotsRequestParams,
    LegacyControllerGetAnyCatchmentStationsRequestParams,
    LegacyControllerGetBlackSpotDataRequestParams,
    LegacyControllerGetCatchmentAlertsRequestParams,
    LegacyControllerGetCatchmentBlackSpotsRequestParams,
    LegacyControllerGetCatchmentDataRequestParams,
    LegacyControllerGetCatchmentPolygonRequestParams,
    LegacyControllerGetCatchmentStationsRequestParams,
    LegacyControllerGetIntervalDataRequestParams,
    LegacyControllerGetPointLocationRequestParams,
    LegacyControllerGetStationDataRequestParams,
    LegacyControllerGetWatershedCatchmentsRequestParams,
    LegacyControllerGetWatershedPolygonRequestParams,
    LegacyControllerGetWatershedPolygonsRequestParams,
    LegacyControllerGetWatershedsRequestParams
} from './legacy.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class LegacyService implements LegacyServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetAllCatchments(requestParameters: LegacyControllerGetAllCatchmentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CatchmentDto>>;
    public legacyControllerGetAllCatchments(requestParameters: LegacyControllerGetAllCatchmentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CatchmentDto>>>;
    public legacyControllerGetAllCatchments(requestParameters: LegacyControllerGetAllCatchmentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CatchmentDto>>>;
    public legacyControllerGetAllCatchments(requestParameters: LegacyControllerGetAllCatchmentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const islandId = requestParameters.islandId;
        if (islandId === null || islandId === undefined) {
            throw new Error('Required parameter islandId was null or undefined when calling legacyControllerGetAllCatchments.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (islandId !== undefined && islandId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>islandId, 'islandId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<CatchmentDto>>(`${this.configuration.basePath}/legacy/island/${encodeURIComponent(String(islandId))}/catchments`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetAllData(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AllDataDto>>;
    public legacyControllerGetAllData(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AllDataDto>>>;
    public legacyControllerGetAllData(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AllDataDto>>>;
    public legacyControllerGetAllData(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<AllDataDto>>(`${this.configuration.basePath}/legacy/alldata`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetAnyCatchmentBlackSpots(requestParameters: LegacyControllerGetAnyCatchmentBlackSpotsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BlackSpotDto>>;
    public legacyControllerGetAnyCatchmentBlackSpots(requestParameters: LegacyControllerGetAnyCatchmentBlackSpotsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BlackSpotDto>>>;
    public legacyControllerGetAnyCatchmentBlackSpots(requestParameters: LegacyControllerGetAnyCatchmentBlackSpotsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BlackSpotDto>>>;
    public legacyControllerGetAnyCatchmentBlackSpots(requestParameters: LegacyControllerGetAnyCatchmentBlackSpotsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const catchmentId = requestParameters.catchmentId;
        if (catchmentId === null || catchmentId === undefined) {
            throw new Error('Required parameter catchmentId was null or undefined when calling legacyControllerGetAnyCatchmentBlackSpots.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<BlackSpotDto>>(`${this.configuration.basePath}/legacy/watersheds/catchments/${encodeURIComponent(String(catchmentId))}/blackspots`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetAnyCatchmentStations(requestParameters: LegacyControllerGetAnyCatchmentStationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<StationDto>>;
    public legacyControllerGetAnyCatchmentStations(requestParameters: LegacyControllerGetAnyCatchmentStationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<StationDto>>>;
    public legacyControllerGetAnyCatchmentStations(requestParameters: LegacyControllerGetAnyCatchmentStationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<StationDto>>>;
    public legacyControllerGetAnyCatchmentStations(requestParameters: LegacyControllerGetAnyCatchmentStationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const catchmentId = requestParameters.catchmentId;
        if (catchmentId === null || catchmentId === undefined) {
            throw new Error('Required parameter catchmentId was null or undefined when calling legacyControllerGetAnyCatchmentStations.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<StationDto>>(`${this.configuration.basePath}/legacy/watersheds/catchments/${encodeURIComponent(String(catchmentId))}/stations`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetBlackSpotData(requestParameters: LegacyControllerGetBlackSpotDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BlackSpotDataDto>;
    public legacyControllerGetBlackSpotData(requestParameters: LegacyControllerGetBlackSpotDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BlackSpotDataDto>>;
    public legacyControllerGetBlackSpotData(requestParameters: LegacyControllerGetBlackSpotDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BlackSpotDataDto>>;
    public legacyControllerGetBlackSpotData(requestParameters: LegacyControllerGetBlackSpotDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const blackspotCode = requestParameters.blackspotCode;
        if (blackspotCode === null || blackspotCode === undefined) {
            throw new Error('Required parameter blackspotCode was null or undefined when calling legacyControllerGetBlackSpotData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<BlackSpotDataDto>(`${this.configuration.basePath}/legacy/blackspots/${encodeURIComponent(String(blackspotCode))}/data`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetCatchmentAlerts(requestParameters: LegacyControllerGetCatchmentAlertsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AlertDto>>;
    public legacyControllerGetCatchmentAlerts(requestParameters: LegacyControllerGetCatchmentAlertsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AlertDto>>>;
    public legacyControllerGetCatchmentAlerts(requestParameters: LegacyControllerGetCatchmentAlertsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AlertDto>>>;
    public legacyControllerGetCatchmentAlerts(requestParameters: LegacyControllerGetCatchmentAlertsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const watershedId = requestParameters.watershedId;
        if (watershedId === null || watershedId === undefined) {
            throw new Error('Required parameter watershedId was null or undefined when calling legacyControllerGetCatchmentAlerts.');
        }
        const catchmentId = requestParameters.catchmentId;
        if (catchmentId === null || catchmentId === undefined) {
            throw new Error('Required parameter catchmentId was null or undefined when calling legacyControllerGetCatchmentAlerts.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<AlertDto>>(`${this.configuration.basePath}/legacy/watersheds/${encodeURIComponent(String(watershedId))}/catchments/${encodeURIComponent(String(catchmentId))}/alerts`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetCatchmentBlackSpots(requestParameters: LegacyControllerGetCatchmentBlackSpotsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BlackSpotDto>>;
    public legacyControllerGetCatchmentBlackSpots(requestParameters: LegacyControllerGetCatchmentBlackSpotsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BlackSpotDto>>>;
    public legacyControllerGetCatchmentBlackSpots(requestParameters: LegacyControllerGetCatchmentBlackSpotsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BlackSpotDto>>>;
    public legacyControllerGetCatchmentBlackSpots(requestParameters: LegacyControllerGetCatchmentBlackSpotsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const watershedId = requestParameters.watershedId;
        if (watershedId === null || watershedId === undefined) {
            throw new Error('Required parameter watershedId was null or undefined when calling legacyControllerGetCatchmentBlackSpots.');
        }
        const catchmentId = requestParameters.catchmentId;
        if (catchmentId === null || catchmentId === undefined) {
            throw new Error('Required parameter catchmentId was null or undefined when calling legacyControllerGetCatchmentBlackSpots.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<BlackSpotDto>>(`${this.configuration.basePath}/legacy/watersheds/${encodeURIComponent(String(watershedId))}/catchments/${encodeURIComponent(String(catchmentId))}/blackspots`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetCatchmentData(requestParameters: LegacyControllerGetCatchmentDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ZoneDataDto>;
    public legacyControllerGetCatchmentData(requestParameters: LegacyControllerGetCatchmentDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ZoneDataDto>>;
    public legacyControllerGetCatchmentData(requestParameters: LegacyControllerGetCatchmentDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ZoneDataDto>>;
    public legacyControllerGetCatchmentData(requestParameters: LegacyControllerGetCatchmentDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const watershedId = requestParameters.watershedId;
        if (watershedId === null || watershedId === undefined) {
            throw new Error('Required parameter watershedId was null or undefined when calling legacyControllerGetCatchmentData.');
        }
        const catchmentId = requestParameters.catchmentId;
        if (catchmentId === null || catchmentId === undefined) {
            throw new Error('Required parameter catchmentId was null or undefined when calling legacyControllerGetCatchmentData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ZoneDataDto>(`${this.configuration.basePath}/legacy/watersheds/${encodeURIComponent(String(watershedId))}/catchments/${encodeURIComponent(String(catchmentId))}/data`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetCatchmentPolygon(requestParameters: LegacyControllerGetCatchmentPolygonRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PolygonDto>;
    public legacyControllerGetCatchmentPolygon(requestParameters: LegacyControllerGetCatchmentPolygonRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PolygonDto>>;
    public legacyControllerGetCatchmentPolygon(requestParameters: LegacyControllerGetCatchmentPolygonRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PolygonDto>>;
    public legacyControllerGetCatchmentPolygon(requestParameters: LegacyControllerGetCatchmentPolygonRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const watershedId = requestParameters.watershedId;
        if (watershedId === null || watershedId === undefined) {
            throw new Error('Required parameter watershedId was null or undefined when calling legacyControllerGetCatchmentPolygon.');
        }
        const catchmentId = requestParameters.catchmentId;
        if (catchmentId === null || catchmentId === undefined) {
            throw new Error('Required parameter catchmentId was null or undefined when calling legacyControllerGetCatchmentPolygon.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PolygonDto>(`${this.configuration.basePath}/legacy/watersheds/${encodeURIComponent(String(watershedId))}/catchments/${encodeURIComponent(String(catchmentId))}/polygon`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetCatchmentStations(requestParameters: LegacyControllerGetCatchmentStationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<StationDto>>;
    public legacyControllerGetCatchmentStations(requestParameters: LegacyControllerGetCatchmentStationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<StationDto>>>;
    public legacyControllerGetCatchmentStations(requestParameters: LegacyControllerGetCatchmentStationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<StationDto>>>;
    public legacyControllerGetCatchmentStations(requestParameters: LegacyControllerGetCatchmentStationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const watershedId = requestParameters.watershedId;
        if (watershedId === null || watershedId === undefined) {
            throw new Error('Required parameter watershedId was null or undefined when calling legacyControllerGetCatchmentStations.');
        }
        const catchmentId = requestParameters.catchmentId;
        if (catchmentId === null || catchmentId === undefined) {
            throw new Error('Required parameter catchmentId was null or undefined when calling legacyControllerGetCatchmentStations.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<StationDto>>(`${this.configuration.basePath}/legacy/watersheds/${encodeURIComponent(String(watershedId))}/catchments/${encodeURIComponent(String(catchmentId))}/stations`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetEFASData(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<HydroDataDto>>;
    public legacyControllerGetEFASData(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<HydroDataDto>>>;
    public legacyControllerGetEFASData(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<HydroDataDto>>>;
    public legacyControllerGetEFASData(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<HydroDataDto>>(`${this.configuration.basePath}/legacy/efasdata`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetIntervalData(requestParameters: LegacyControllerGetIntervalDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<VariableDataDto>>;
    public legacyControllerGetIntervalData(requestParameters: LegacyControllerGetIntervalDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<VariableDataDto>>>;
    public legacyControllerGetIntervalData(requestParameters: LegacyControllerGetIntervalDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<VariableDataDto>>>;
    public legacyControllerGetIntervalData(requestParameters: LegacyControllerGetIntervalDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const variable = requestParameters.variable;
        if (variable === null || variable === undefined) {
            throw new Error('Required parameter variable was null or undefined when calling legacyControllerGetIntervalData.');
        }
        const fromDate = requestParameters.fromDate;
        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling legacyControllerGetIntervalData.');
        }
        const toDate = requestParameters.toDate;
        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling legacyControllerGetIntervalData.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (variable) {
            variable.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'variable');
            })
        }
        if (fromDate !== undefined && fromDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromDate, 'fromDate');
        }
        if (toDate !== undefined && toDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>toDate, 'toDate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<VariableDataDto>>(`${this.configuration.basePath}/legacy/intervaldata`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetIslands(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<IslandDto>>;
    public legacyControllerGetIslands(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<IslandDto>>>;
    public legacyControllerGetIslands(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<IslandDto>>>;
    public legacyControllerGetIslands(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<IslandDto>>(`${this.configuration.basePath}/legacy/islands`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetPointLocation(requestParameters: LegacyControllerGetPointLocationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MapPointDto>;
    public legacyControllerGetPointLocation(requestParameters: LegacyControllerGetPointLocationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MapPointDto>>;
    public legacyControllerGetPointLocation(requestParameters: LegacyControllerGetPointLocationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MapPointDto>>;
    public legacyControllerGetPointLocation(requestParameters: LegacyControllerGetPointLocationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const latitude = requestParameters.latitude;
        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling legacyControllerGetPointLocation.');
        }
        const longitude = requestParameters.longitude;
        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling legacyControllerGetPointLocation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<MapPointDto>(`${this.configuration.basePath}/legacy/pointLocation/${encodeURIComponent(String(latitude))}/${encodeURIComponent(String(longitude))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetStationData(requestParameters: LegacyControllerGetStationDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<StationDataDto>;
    public legacyControllerGetStationData(requestParameters: LegacyControllerGetStationDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<StationDataDto>>;
    public legacyControllerGetStationData(requestParameters: LegacyControllerGetStationDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<StationDataDto>>;
    public legacyControllerGetStationData(requestParameters: LegacyControllerGetStationDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const stationCode = requestParameters.stationCode;
        if (stationCode === null || stationCode === undefined) {
            throw new Error('Required parameter stationCode was null or undefined when calling legacyControllerGetStationData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<StationDataDto>(`${this.configuration.basePath}/legacy/stations/${encodeURIComponent(String(stationCode))}/data`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetWatershedCatchments(requestParameters: LegacyControllerGetWatershedCatchmentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CatchmentDto>>;
    public legacyControllerGetWatershedCatchments(requestParameters: LegacyControllerGetWatershedCatchmentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CatchmentDto>>>;
    public legacyControllerGetWatershedCatchments(requestParameters: LegacyControllerGetWatershedCatchmentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CatchmentDto>>>;
    public legacyControllerGetWatershedCatchments(requestParameters: LegacyControllerGetWatershedCatchmentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const watershedId = requestParameters.watershedId;
        if (watershedId === null || watershedId === undefined) {
            throw new Error('Required parameter watershedId was null or undefined when calling legacyControllerGetWatershedCatchments.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<CatchmentDto>>(`${this.configuration.basePath}/legacy/watersheds/${encodeURIComponent(String(watershedId))}/catchments`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetWatershedPolygon(requestParameters: LegacyControllerGetWatershedPolygonRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PolygonDto>;
    public legacyControllerGetWatershedPolygon(requestParameters: LegacyControllerGetWatershedPolygonRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PolygonDto>>;
    public legacyControllerGetWatershedPolygon(requestParameters: LegacyControllerGetWatershedPolygonRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PolygonDto>>;
    public legacyControllerGetWatershedPolygon(requestParameters: LegacyControllerGetWatershedPolygonRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const watershedId = requestParameters.watershedId;
        if (watershedId === null || watershedId === undefined) {
            throw new Error('Required parameter watershedId was null or undefined when calling legacyControllerGetWatershedPolygon.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PolygonDto>(`${this.configuration.basePath}/legacy/watersheds/${encodeURIComponent(String(watershedId))}/polygon`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetWatershedPolygons(requestParameters: LegacyControllerGetWatershedPolygonsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ManyPolygonDto>;
    public legacyControllerGetWatershedPolygons(requestParameters: LegacyControllerGetWatershedPolygonsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ManyPolygonDto>>;
    public legacyControllerGetWatershedPolygons(requestParameters: LegacyControllerGetWatershedPolygonsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ManyPolygonDto>>;
    public legacyControllerGetWatershedPolygons(requestParameters: LegacyControllerGetWatershedPolygonsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const watershedId = requestParameters.watershedId;
        if (watershedId === null || watershedId === undefined) {
            throw new Error('Required parameter watershedId was null or undefined when calling legacyControllerGetWatershedPolygons.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ManyPolygonDto>(`${this.configuration.basePath}/legacy/watersheds/${encodeURIComponent(String(watershedId))}/polygons`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyControllerGetWatersheds(requestParameters: LegacyControllerGetWatershedsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<WatershedDto>>;
    public legacyControllerGetWatersheds(requestParameters: LegacyControllerGetWatershedsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<WatershedDto>>>;
    public legacyControllerGetWatersheds(requestParameters: LegacyControllerGetWatershedsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<WatershedDto>>>;
    public legacyControllerGetWatersheds(requestParameters: LegacyControllerGetWatershedsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const islandId = requestParameters.islandId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (islandId !== undefined && islandId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>islandId, 'island_id');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<WatershedDto>>(`${this.configuration.basePath}/legacy/watersheds`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
